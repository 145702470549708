<template>
  <div>
    <mq-layout :mq="['mobile', 'tablet']">
      <catalogue-mobile :gender="gender"></catalogue-mobile>
    </mq-layout>
    <mq-layout mq="desktop">
      <catalogue-desktop :gender="gender"></catalogue-desktop>
    </mq-layout>
  </div>
</template>

<script>
import CatalogueDesktop from '@/components/Catalogue/CatalogueDesktop/CatalogueDesktop.vue'
import CatalogueMobile  from '@/components/Catalogue/CatalogueMobile/CatalogueMobile.vue'

export default {
  props: {
    gender: {
      required: true,
      type: String
    }
  },

  components: {
    CatalogueDesktop,
    CatalogueMobile
  },

  metaInfo() {
    return {
      title: 'My Shop'
    }
  }
}
</script>
